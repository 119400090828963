import React, { useEffect } from 'react';
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Home from './components/Home';
import { useDocTitle } from './components/CustomHook';

function App() {

  useDocTitle("Praxes");

  // When the app mounts, trigger a call to the backend to subscribe to Drive changes.
  useEffect(() => {
    fetch('/api/subscribe-drive-changes', {
      method: 'POST'
    })
    .then(response => response.json())
    .then(data => {
      console.log("Drive subscription successful:", data);
    })
    .catch(error => {
      console.error("Error subscribing to drive changes:", error);
    });
  }, []);

  return (
    <>
      <Router>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
      </Router>
    </>
  );
}

export default App;
